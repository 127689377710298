@import '../../../styles/globalVariables';
@import '../../../styles/mixins';

.headtag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  gap: 6px;
  margin-bottom: 12px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;

  @include for-size(phone-only) {
    margin-bottom: 0px;
    top: 8px;
    width: calc(100% - 16px);
    left: 8px;
  }
  .innerTagBox {
    display: flex;
    align-items: center;
    gap: 6px;

    i {
      display: flex;
    }

    .property_tag {
      font-weight: 500;
      color: $white;
      line-height: 16px;
      background: #485661;
      border-radius: 4px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      width: 69px;
      height: 24px;
      padding: 0 8px 0 6px;
      html[dir='rtl'] & {
        padding: 0 6px 0 8px;
        width: 69px;
      }
    }
    .cancel_tag {
      font-weight: 500;
      color: $white;
      line-height: 16px;
      background: #36434E;
      border-radius: 4px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      min-width: 20px;
      height: 24px;
      padding: 0 8px 0 6px;
      html[dir='rtl'] & {
        padding: 0 6px 0 8px;
      }
    }
    .sold_tag {
      font-weight: 500;
      color: #1D252D;
      line-height: 16px;
      background: #E6F2ED;
      border-radius: 4px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      width: 54px;
      height: 24px;
      padding: 0 8px 0 6px;
      html[dir='rtl'] & {
        padding: 0 6px 0 8px;
        width: 54px;
      }
    }
    .propertyAsset_tag {
      padding: 4px 8px;
      height: 24px;
      color: #2D318E;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      background: #F0F3FD;
      border-radius: 4px;
      text-transform: capitalize;
    }
    .registerTag {
      padding: 2px 8px;
      height: 20px;
      background: #f9f5fe;
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #6f10a2;
    }
    .upcoming_tag, .reject_tag {
      font-weight: 500;
      line-height: 16px;
      color: #1D252D;
      background:  #FFEECD;
      border-radius: 4px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      min-width: 20px;
      height: 24px;
      padding: 0 8px 0 6px;
      html[dir='rtl'] & {
        padding: 0 6px 0 8px;
        min-width: 20px;
      }
    }
    .livepropTag {
      font-weight: 500;
      line-height: 16px;
      background: #048848;
      border-radius: 4px;
      color: #ffffff;
      position: relative;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      min-width: 20px;
      height: 24px;
      padding: 0 8px 0 6px;
      html[dir='rtl'] & {
        padding: 0 6px 0 8px;
        min-width: 20px;
      }
    }
  }
}

.gdpcardtitledeed {
  margin: 8px 0px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @include for-size(phone-only) {
    display: none;
  }
  .titledeedText {
    display: block;
    height: 20px;

    color: #737373;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.gdpcardtitledeedmobile {
  margin-top: 8px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  @include for-size(phone-only) {
    display: flex;
  }
  .titledeedText {
    display: block;
    height: 20px;

    color: #737373;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    @include for-size(phone-only) {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      height: auto;
    }
  }
}

.statusActivityBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: auto;
  width: 347px;
  @include for-size(phone-only) {
    width: 100%;
  }
  .registernFolllowBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 27px;
    .regWrapper {
      .regfolwcount {
        color: #191919;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .regfolwtext {
        color: #737373;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: block;
      }
    }
  }
  .bidHistoryBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    div {
      .regfolwcount {
        color: #191919;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .regfolwtext {
        color: #737373;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: block;
      }
    }

    .bidstatusBox {
      text-align: center;
      .bidHistoryText {
        color: #6f10a2;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: block;
      }
    }
  }
}
.property_box {
  margin-bottom: 16px;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  .propertymainBox {
    padding: 16px 12px;
    background: #ffffff;

    border-radius: 12px;
    @include for-size(phone-only) {
      padding: 12px 12px;
    }
    .property_card {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      max-width: 808px;
      width: 100%;

      @include for-size(phone-only) {
        flex-direction: column;
      }
      .mainconteiner {
        display: flex;
        gap: 12px;
        @include for-size(phone-only) {
          height: 88px;
        }

        .image_box {
          width: 200px;
          height: 204px;
          @include for-size(phone-only) {
            width: 104px;
            height: 90px;
          }
          img {
            max-width: 200px;
            height: 204px;
            width: 100%;
            border-radius: 4.48px;
            @include for-size(phone-only) {
              max-width: 104px;
              height: 90px;
              width: 100%;
            }
          }
        }
        .main_content {
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            padding: 0;
            max-width: 343px;
            color: #191919;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include for-size(phone-only) {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              max-width: 203px;
            }
          }
          .icon_box {
            margin-top: 8px;
            display: flex;
            gap: 24px;
            align-items: center;
            color: #262626;
            @include for-size(phone-only) {
              margin-top: 8px;
              gap: 10px;
            }
            span {
              height: 18px;
              display: flex;
              gap: 8px;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              align-items: center;
              i {
                font-size: 18px;
                color: #737373;
              }

              span {
                display: block;
                // max-width: 100px;
                // white-space: normal;
                // text-overflow: ellipsis;
                // overflow: hidden;
              }
              .sqmunit {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #737373;
                margin-left: -4px;
                margin-top: 4px;
                text-transform: lowercase;
              }
            }
            .attrValue {
              direction: ltr;
            }
          }
        }
      }
      .action_box {
        display: flex;
        flex-direction: column;
        text-align: right;
        html[dir='rtl'] & {
          text-align: left;
        }
        .ctaWrapperBox {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          gap: 12px;
          @include for-size(phone-only) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }
        }
        @include for-size(phone-only) {
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding-top: 8px;
          gap: 8px;
          border-top: 1px dashed #d2d2d2;
          text-align: left;
          &.hide_box {
            display: none;
          }
        }
        .actbtnbox {
          html[dir='rtl'] & {
            text-align: left;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #737373;
            @include for-size(phone-only) {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              html[dir='rtl'] & {
                text-align: right;
              }
            }
            &:nth-child(2) {
              margin-bottom: 16px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #191919;
              display: flex;
              align-items: baseline;
              gap: 4px;
              justify-content: flex-end;
              @include for-size(phone-only) {
                margin-bottom: 0px;
                justify-content: flex-start;
                html[dir='rtl'] & {
                  justify-content: flex-start;
                }
              }
              .currencySpace {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                text-align: right;
                color: #737373;
              }
            }
          }
        }

        .regbtn {
          padding: 6px 16px;
          width: 152px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          gap: 8px;
          height: 36px;
          background: #6f10a2;
          border: 1px solid #6f10a2;
          border-radius: 8px;
          color: white;
          white-space: nowrap;
          cursor: pointer;
          @include for-size(phone-only) {
            width: calc(100% / 2);
          }
          &:disabled,
          &[disabled] {
            background: #d2d2d2;
            color: #737373;
            border: 1px solid #d2d2d2;
            cursor: default;
          }
        }

        .viewdetailBtn {
          padding: 6px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          height: 36px;
          width: 152px;
          gap: 4px;
          background: #ffffff;
          border: 1px solid #6f10a2;
          border-radius: 8px;
          color: #6f10a2;
          cursor: pointer;
          @include for-size(phone-only) {
            width: calc(100% / 2);
          }
          i {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .liveBidderBox {
      margin-top: 16px;
      border-top: 1px dashed #d2d2d2;

      .viewDetailFollowCount{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #191919;
          position: relative;
          display: flex;
          align-items: center;
          justify-content:start;
          margin-top: 8px;
          .followCountWrapper{
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            html[dir='rtl'] &{
              margin-left: 12px;
              margin-right: 0px;
              // flex-direction: row-reverse;
            }
            .count{
              color:#191919;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              @include for-size(phone-only){
                color:#191919;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
              }
            }
            .bellIcon{
              i{
                display: block;
                width: 16px;
                height: 16px;
              }
            }
            .followCount{
              color: #737373;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              @include for-size(phone-only){
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
              }
            }
          
          }
      }
      .gdpcardtitledeedmobile{
        margin: 8px 0px;
        display:none;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        @include for-size (phone-only) {
          display: flex;
        }
        .titledeedText{
          display: block;
          height: 20px;
          
          color: #737373;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          @include for-size (phone-only) {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            height: auto;
          }
        }
      }
      .liveactionmainbox{
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include for-size(phone-only) {
          display: none;
        }

        .leftsection {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #191919;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .followCountWrapper {
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            html[dir='rtl'] & {
              margin-left: 12px;
              margin-right: auto;
              // flex-direction: row-reverse;
            }
            .count {
              color: #191919;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
            .bellIcon {
              i {
                display: block;
                width: 16px;
                height: 16px;
              }
            }
            .followCount {
              color: #737373;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
            .dividerLine {
              display: inline-block;
              width: 2px;
              height: 22px;
              margin-left: 8px;
              html[dir='rtl'] & {
                margin-left: auto;
                margin-right: 8px;
              }
              background-color: #f0f0f0;
            }
          }
          .activebid {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #737373;
            margin-left: 4px;
            html[dir='rtl'] & {
              margin-right: 4px;
              @include for-size(phone-only) {
                margin-right: 28px;
              }
            }
          }
          div {
            display: inline-block;
            position: relative;
            .bidActive {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #6f10a2;
              margin-left: 32px;
              html[dir='rtl'] & {
                margin-right: 32px;
              }
              &::after {
                content: '\f1d7';
                position: absolute;
                font-family: 'iconography', sans-serif !important;
                top: 4px;
                right: -24px;
                font-weight: 800;
                transform: rotate(180deg);
                html[dir='rtl'] & {
                  left: 8px;
                  right: auto;
                  transform: rotate(0deg);
                }
              }
              &::before {
                content: '';
                position: absolute;
                top: 2px;
                left: 16px;
                width: 2px;
                height: 22px;
                background-color: #f0f0f0;
                html[dir='rtl'] & {
                  left: auto;
                  right: 13px;
                }
              }
            }
          }
        }
        .rightsection {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #737373;
          display: flex;
          align-items: baseline;
          gap: 4px;
          .bidAmount {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #191919;
            margin-left: 8px;
            html[dir='rtl'] & {
              margin-left: 0px !important;
              margin-right: 8px;
            }
          }
          .Sarunit {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #737373;
          }
        }
      }
      .mobileBox {
        @include for-size(phone-only) {
          margin-top: 3px;
        }
        .mobileLiveActionBox {
          .curBid {
            text-align: left;
            html[dir='rtl'] & {
              text-align: right;
            }
            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #737373;
            }
            div {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #191919;
              display: flex;
              align-items: baseline;
              gap: 4px;
              span {
                line-height: 20px;
              }
            }
          }
          .depoAmount {
            text-align: right;
            html[dir='rtl'] & {
              text-align: left;
            }
            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #737373;
            }
            div {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #191919;
              display: flex;
              align-items: baseline;
              justify-content: flex-end;
              gap: 4px;
              span {
                line-height: 20px;
              }
            }
          }
        }
        .mobleftsection {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #191919;
          position: relative;
          display: none;
          border-top: 1px dashed #d2d2d2;
          height: 34px;
          padding-top: 8px;
          margin-top: 8px;
          justify-content: space-between;
          align-items: center;
          @include for-size(phone-only) {
            display: flex;
          }
          .mobactivebid {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            .bidText {
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              color: #737373;
              position: relative;
              height: 14px;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              height: 20px;
              color: #191919;
              margin-right: 4px;
              @include for-size(phone-only) {
                display: block;
                html[dir='rtl'] & {
                  margin-right: 0px;
                  margin-left: 4px;
                }
              }
            }
          }
          .mobbidActive {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #6f10a2;
            margin-right: 18px;
            html[dir='rtl'] & {
              margin-left: 18px;
            }
            &::after {
              content: '\f1d7';
              position: absolute;
              font-family: 'iconography', sans-serif !important;
              top: 12px;
              right: -2px;
              font-weight: 600;
              transform: rotate(180deg);
              html[dir='rtl'] & {
                left: -2px;
                right: auto;
                transform: rotate(0deg);
              }
            }
          }
        }
      }

      .mobCtaWraper {
        display: none;
        @include for-size(phone-only) {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-top: 16px;
          .followBtn {
            display: none;
            @include for-size(phone-only) {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              width: calc(100% / 2);
              cursor: pointer;
              height: 36px;
              background: #ffffff;
              border-radius: 8px;
              color: #6f10a2;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              border: 1px solid #6f10a2;
            }
          }
        }
        .bidnowbtn {
          display: none;
          @include for-size(phone-only) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            flex-grow: 1;
            width: calc(100% / 2);
            padding: 6px auto;
            cursor: pointer;
            height: 36px;
            background: #6f10a2;
            border-radius: 8px;
            color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border: none;
            &:disabled,
            &[disabled] {
              background: #d2d2d2;
              color: #737373;
              border: 1px solid #d2d2d2;
            }
          }
        }
      }

      .viewdetailBtn {
        display: none;
        margin-top: 16px;
        @include for-size(phone-only) {
          display: block;
          width: 100%;
          padding: 6px auto;
          min-width: 120px;
          cursor: pointer;
          height: 36px;
          background: #ffffff;
          border-radius: 8px;
          color: #6f10a2;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #6f10a2;
        }
      }
      .mobileLiveActionBox {
        display: none;
        @include for-size(phone-only) {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.propertyInrPop {
  padding: 16px 24px;
  width: 856px;
  background: #f7f7f7;
  .loader_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    &:last-child {
      .property_box {
        margin-bottom: 0 !important;
      }
    }
  }
  @include for-size(tablet-phone) {
    width: 100%;
    background: #fff;
    padding: 16px;
  }
}

.PcustomClass {
  padding: 20px 24px !important;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2.71324px 5.125px rgba(0, 0, 0, 0.035), 0px 1.12694px 2.12866px rgba(0, 0, 0, 0.0456112),
    0px 0.407592px 0.769896px rgba(0, 0, 0, 0.07);
  @include for-size(phone-only) {
    padding: 20px 16px !important;
    border-radius: 0;
    border-bottom: 16px solid #f7f7f7;
    box-shadow: none;
  }
  h2 {
    font-weight: 500 !important;
    color: #191919 !important;
  }
  i {
    right: 24px !important;
    top: 25px !important;
    html[dir='rtl'] & {
      right: inherit !important;
      left: 24px !important;
    }
  }
}
.bodyP {
  padding: 0 !important;
}
.skeleton_spacing {
  margin-bottom: 4px !important;
}
.skeleton_spacing_more {
  display: inline-block;
  margin-bottom: 4px;
}

.sarAmount {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #191919;
}

.groupAddress {
  color: #191919;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 435px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include for-size(phone-only) {
    margin-bottom: 2px;
    max-width: 196px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.bidIcon {
  i {
    display: block;
    width: 16px;
    height: 16px;
  }
}

// -------------------------------revamp card----------------------
.gdpRevampCardWrapper {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e1e4e8;
  background: #fff;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 258px;
  margin-bottom: 16px;
  @include for-size(phone-only) {
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    height: auto;
  }
  .cardImageWrapper {
    position: relative;
    width: 334px;
    height: 258px;
    @include for-size(phone-only) {
      width: 100%;
      height: 180px;
    }
    img {
      width: 334px;
      height: 258px;
      @include for-size(phone-only) {
        width: 100%;
        height: 180px;
      }
    }
  }
  .revContentWrapper {
    width: 100%;
    padding: 16px 16px 16px 0px;
    position: relative;
    html[dir='rtl'] & {
      padding: 16px 0px 16px 16px;
    }
    height: 258px;
    @include for-size(phone-only) {
      padding: 0px !important;
      height: auto;
    }
    .headerTitle{
      height: auto;
      @include for-size(phone-only) {
        height: auto;
      }
      .cardTitle{
        width:442px;
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        -webkit-line-clamp: 1; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        color: #1d252d;
        font-size: 18px !important;
        font-weight: 600 !important;
        line-height: normal;
        @include for-size(phone-only) {
          font-size: 16px !important;
          font-weight: 500 !important;
          width:100%;
          display:flex; 
          overflow:initial; 
          text-overflow:unset; 
          color: #1d252d;
        }
      }
      .address {
        margin-top: 4px;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1D252D;
        @include for-size(phone-only) {
          margin-top: 4px;
          margin-bottom: 0px;
        }
      }
    }
    .timerMainSection {
      height: 40px;
      margin: 12px 0px 12px 0px;
      @include for-size(phone-only) {
        height: 56px;
      }
      .timerSection {
        border-top: 1px dashed #d2d2d7;
        border-bottom: 1px dashed #d2d2d7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include for-size(phone-only) {
          margin: 12px auto;
          height: 56px;
        }
        .innerWrap {
          padding: 8px 12px;
          color: #707b89;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          @include for-size(phone-only) {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &:first-child {
            width: fit-content;
            padding: 8px 0px;
            @include for-size(phone-only) {
              width: 24px !important;
            }
          }
          &:nth-child(2) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
        
          }
          &:nth-child(3) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          &:nth-child(4) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          &:last-child {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          span {
            color: #1d252d;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          i {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
        .innerWrapColons{
          color: #707b89;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .timerSectionInline {
        border: 1px solid #d2d2d7;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        @include for-size(phone-only) {
          margin: 12px auto;
          height: 56px;
          display: flex;
        }
        .innerWrap {
          padding: 8px 12px;
          color: #707b89;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          @include for-size(phone-only) {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100% !important;
          }
          &:first-child {
            width: fit-content;
            padding: 8px 0px;
            @include for-size(phone-only) {
              width: 24px !important;
              display: none;
            }
          }
          &:nth-child(2) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
        
          }
          &:nth-child(3) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          &:nth-child(4) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          &:last-child {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          span {
            color: #1d252d;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          i {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
        .innerWrapColons{
          color: #707b89;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    .timerMainSectionInline {
      height: 40px;
      margin: 20px 0px 12px 0px;
      @include for-size(phone-only) {
        height: 56px;
      }
      .timerSection {
        border-top: 1px dashed #d2d2d7;
        border-bottom: 1px dashed #d2d2d7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include for-size(phone-only) {
          margin: 12px auto;
          height: 56px;
        }
        .innerWrap {
          padding: 8px 12px;
          color: #707b89;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          @include for-size(phone-only) {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &:first-child {
            width: fit-content;
            padding: 8px 0px;
            @include for-size(phone-only) {
              width: 24px !important;
            }
          }
          &:nth-child(2) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
        
          }
          &:nth-child(3) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          &:nth-child(4) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          &:last-child {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
            }
          }
          span {
            color: #1d252d;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          i {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
        .innerWrapColons{
          color: #707b89;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .timerSectionInline {
        border: 1px solid #d2d2d7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include for-size(phone-only) {
          margin: 12px auto;
          height: 56px;
          display: flex;
        }
        .innerWrap {
          padding: 8px 12px;
          color: #707b89;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          @include for-size(phone-only) {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100% !important;
          }
          &:first-child {
            width: fit-content;
            padding: 8px 0px;
            @include for-size(phone-only) {
              width: 24px !important;
              display: none;
            }
          }
          &:nth-child(2) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
        
          }
          &:nth-child(3) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          &:nth-child(4) {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          &:last-child {
            width: fit-content;
            @include for-size(phone-only) {
              width: 75px !important;
              width: 100% !important;
            }
          }
          span {
            color: #1d252d;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          i {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
        .innerWrapColons{
          color: #707b89;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    .timerSection {
      border-top: 1px dashed #d2d2d7;
      border-bottom: 1px dashed #d2d2d7;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0px 12px 0px;
      height: 40px;
      @include for-size(phone-only) {
        margin: 12px auto;
      }
      .innerWrap {
        padding: 8px 12px;
        color: #707b89;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        @include for-size(phone-only) {
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        &:first-child {
          width: fit-content;
          padding: 8px 0px;
          @include for-size(phone-only) {
            width: 24px !important;
          }
        }
        &:nth-child(2) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
      
        }
        &:nth-child(3) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        &:nth-child(4) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        &:last-child {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        span {
          color: #1d252d;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        i {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
      .innerWrapColons{
        color: #707b89;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .timerSectionInline {
      border: 1px solid #d2d2d7;
      border-radius: 6px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      @include for-size(phone-only) {
        margin: 12px auto;
        display: flex;
      }
      .innerWrap {
        padding: 8px 12px;
        color: #707b89;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        @include for-size(phone-only) {
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100% !important;
        }
        &:first-child {
          width: fit-content;
          padding: 8px 0px;
          @include for-size(phone-only) {
            width: 24px !important;
          }
        }
        &:nth-child(2) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
      
        }
        &:nth-child(3) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        &:nth-child(4) {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        &:last-child {
          width: fit-content;
          @include for-size(phone-only) {
            width: 75px !important;
          }
        }
        span {
          color: #1d252d;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        i {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
      .innerWrapColons{
        color: #707b89;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .revampPrerequisite {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include for-size(phone-only){
          height: 40px;
      }
      .innerPrerequisiteLeft {
        text-align: left;
        html[dir='rtl'] & {
          text-align: right;
        }
        .depAmount{
          color: #1d252d;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin: 0px !important;
        
        }
        .depText {
          display: block;
          color: #36434e;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          @include for-size(phone-only){
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
      .innerPrerequisiteRight {
        text-align: right;
        html[dir='rtl'] & {
          text-align: left;
        }
       
        .startBidAmount{
          color: #1d252d;
          min-width: 80px;
          min-height: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin: 0px !important;
        }
        .startBidText{
          display: block;
          color: #36434e;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          @include for-size(phone-only){
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
    .actionBtnDesktop {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      @include for-size(phone-only) {
        display: none;
      }

      button {
        border-radius: 8px;
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }

      .bidBtn {
        width: 143px;
        background: #f3f9f6;
        padding: 0px 12px;
        gap: 4px;
        color: #048848;
        span {
          i {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
      }
      .placeBidBtn {
        width: 143px;
        color: #6f10a2;
        background: #f9f5fe;
        gap: 4px;
        span {
          i {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
        &.viewDetailsBtn{
          min-width: 143px;
          width: auto;
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      .historyBidBtn {
        background: #f3f9f6;
        width: 200px;
        padding: 0px 12px;
        gap: 4px;
        color: #048848;
        span {
          i {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
      }

      .noBidBtn {
        color: #2b3741;
        background: #f3f6fa;
        padding: 0px 12px;
      }
    }
    .actionBtnMobile {
      display: none;
      @include for-size(phone-only) {
        display: block;
      }
      margin-top: 12px;
      button {
        border-radius: 8px;
        display: flex;
        height: 36px;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
      .twoBtnSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        .bidBtn {
          background: #f3f9f6;
          width: 100%;
          padding: 0px 12px;
          gap: 4px;
          color: #048848;
          span {
            i {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
        }
        .placeBidBtn {
          color: #6f10a2;
          background: #f9f5fe;
          width: 100%;
          gap: 4px;
          span {
            i {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
        }
        .noBidPlaced {
          color: #2b3741;
          background: #f3f6fa;
          width: 100%;
          gap: 4px;
          span {
            i {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .oneBtnSection {
        .viewAndRegBtn {
          color: #6f10a2;
          background: #f9f5fe;
          width: 100%;
        }
        .historyBidBtn {
          background: #f3f9f6;
          width: 100%;
          padding: 0px 12px;
          gap: 4px;
          color: #048848;
          span {
            i {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

.followBtn {
  border-radius: 8px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  color: #36434e;
  background: #f3f6fa;
  width: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  @include for-size(phone-only) {
    height: 32px;
    width: auto;
    float: right;
    border-radius: 24px;
    color: #36434e;
    background: #fff;
    padding: 8px 12px;
    html[dir='rtl'] &{
      padding: 8px 12px;
    }
  }
  span {
    i {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  .mobCounter {
    display: none;
    @include for-size(phone-only) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 26px;
      min-height: 25px;
      border-radius: 50%;
      background-color: #F3F6FA;
      color: #36434E;
    }
  }
  .pastMobCounter {
    @include for-size(phone-only) {
      color: #36434E;
    }
  }
}

.followedBtn {
  @include for-size(phone-only) {
    padding: 8px 4px 8px 8px;
    html[dir='rtl'] &{
      padding: 8px 8px 8px 4px;
    }
  }
}

.showFollowText{
  @include for-size(phone-md) {
    display: none;
  }
}

.deskCounter {
  display: block;
  @include for-size(phone-only) {
    display: none;
  }
}

.timerTag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @include for-size(phone-only) {
    gap: 4px;
  }
  border-radius: 0px 0px 12px 12px;
  color: #1d252d;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 8px;
  .startInText {
    color: #36434e;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
.upcomingTimerBg, .rejectTimerBg {
  background: #fffaf0;
}
.liveTimerBg {
  background: #e6f2ed;
}
.pastTimer {
  color: #191919;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 8px;
  }
}
.colorForPast {
  color: #707B89;
}
.gdpSkeleton{
  width:100%;
  height: auto;
  background: linear-gradient(107deg, #ECECF0 -9.08%, #D9D9E0 109.23%);
}
.socketDesignRightSide {
  height: auto;
  background: linear-gradient(107deg, #ECECF0 -9.08%, #D9D9E0 109.23%);
}
.followOnly {
  border-radius: 8px !important;
  border: 1px solid #F3F6FA !important;
  background: #FFF !important;

}

.actionWrapper{
  position: absolute;
  bottom: 16px;
  @include for-size(phone-only){
    position: static;
  }
}

.blurCard {
  filter: blur(3px);
}

.disabledDiv {
  pointer-events: auto; /* Ensure the div itself is clickable */
}

.disabledDiv * {
  pointer-events: none; /* Disable interaction for all child elements */
}

.EditStrip_Text_Wrapper {
  display: flex;
  line-height: $lineH16;
  flex-direction: column;
  gap: $gap4;
}

.EditStrip_heading {
  color: $Cool-Grey-900;
  font-size: $font14;
  font-weight: $font-semi-bold;
  line-height: $lineH20;
}

.EditStrip_below_heading {
  color: $Cool-Grey-500;
  font-size: $font13;
  font-weight: $default-font-weight;
  line-height: $lineH16;
}

.overrideBlur {
  display: flex;
  padding: $pad8 $pad16;
  align-items: flex-start;
  border-radius: $radius0 $radius0 $radius4 $radius4;
  border: 1px solid var(--Red-200, #F9C4BE);
  background: $Red-50;
  gap: $gap8;
  position: absolute;
  bottom: 0;
  width: 100%;
  @include for-size(tablet-up) {
    position: static; 
    bottom: auto;
    width: auto;
    margin-left: -$mrg16;
    margin-right: -$mrg15;
    margin-bottom: -$mrg15;
    border-left: 0;
    border-radius: $radius0 $radius0 $radius4 $radius0;
    html[dir='rtl'] & {
      border-right: $radius0;
      border-left: 1px solid $Red-200;
      border-radius: $radius0 $radius0 $radius0 $radius4;
      margin-left: -$mrg15;
      margin-right: -$mrg16;
    }
  }

}

.gpdEditMode {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.positionRel {
  position: relative;
}

.Edit_property_image {
  border-radius: $radius0;
  background: #D2D2D2;
  object-fit: contain;
}  
.cancelStampWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  top: 0;
  @include for-size(phone-only) {
    margin-left: -8px;
    margin-right: -8px;
  }
  img {
    position: relative !important;
    width: 188px !important;
    height: 154px !important;
    @include for-size(phone-only) {
      width: 204px !important;
      height: 164px !important;
    }
  }
}

.gdpMobileBid {
  display: flex;
  gap: 2px;
  color: #1D252D;

  .number {
    color: #6F10A2;
    font-weight: 600;
  }
}
.navPreview,
.navNext {
    width: 40px;
    height: 40px;
    line-height: 37px;
    text-align: center;
    background: $white;
    border: 1px solid #d2d2d7;
    border-radius: 50%;
    box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.06), 0px 2.71324px 5.125px rgba(0, 0, 0, 0.035),
        0px 1.12694px 2.12866px rgba(0, 0, 0, 0.0456112), 0px 0.407592px 0.769896px rgba(0, 0, 0, 0.07);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    cursor: pointer;

    &:after {
        font-family: swiper-icons, sans-serif;
        font-display: swap;
        font-size: $font12;
        color: $violet;
    }

    @include for-size(tablet-phone) {
        display: none;
    }

    &:hover {
        background: $violet;
        border: 1px solid $violet;

        &:after {
            color: $white;
        }
    }
}

.navPreview {
    left: 12px;

    &:after {
        content: 'prev';
        margin-right: 3px;
    }
}

.navNext {
    right: 12px;

    &:after {
        content: 'next';
        margin-left: 3px;
    }
}

.swiperButtonDisabled {
    cursor: not-allowed;
    background: #f2f2f3 !important;
    border: 1px solid #d2d2d7 !important;

    &::after {
        color: #d2d2d7;
    }

    &:hover {
        &::after {
            color: #d2d2d7;
        }
    }
}

.cardImageWrapper {
  position: relative;

  .navPreview, .navNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; 
    transition: opacity 0.5s ease, left 0.5s ease, right 0.5s ease; 
    cursor: pointer;
  }

  .navPreview {
    left: -35px; 
  }
  
  .navNext {
    right: -35px;
  }

  &:hover {
    .navPreview, .navNext {
      opacity: 1;
    }

    .navPreview {
      left: 10px;
    }

    .navNext {
      right: 10px; 
    }
  }
}
